import React, { useState, useEffect } from "react";
import HeaderSales from "../common/HeaderSales";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import ResponsivePagination from "react-responsive-pagination";
import { saveAs } from "file-saver";
import Oval from "../../loader/CircleLoade";
import { useFormik } from "formik";
export default function SalesTicketReport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [results, setResults] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState("10");
  const [searchRecord, setSearchRecord] = useState();
  const [selectStatus, setSelectStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [phoneNum, setPhoneNum] = useState("")

  // const dateString = fromDate;
  // const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
  // const dateString2 = toDate;
  // const formattedDate2 = moment(dateString2).format('YYYY-MM-DD HH:mm:ss');
  // const start = formattedDate.replace(/:/g, '%3A')
  // const end = formattedDate2.replace(/:/g, '%3A')

  const formattedDate = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
  const formattedDate2 = moment(toDate).format("YYYY-MM-DD 23:59:59");
  const start = formattedDate.replace(/:/g, "%3A");
  const end = formattedDate2.replace(/:/g, "%3A");

  useEffect(() => {
    salesTicketReport(searchFormik.values)
  }, [currentPage]);

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      dateFrom: "",
      dateTo: "",
      status: "",
    },
    validate: (data) => {
      let errors = {};

      return errors;
    },
    onSubmit: (data) => {
        salesTicketReport(data);
    //   console.log('formikData', data)
    },
  });

  const salesTicketReport = (data) => {
    let token = localStorage.getItem("token");

    try {
      const searchFromDate = data.dateFrom === "" ? "" : `&start=${dateFormater(data.dateFrom)}`;
      const searchToDate = data.dateTo === "" ? "" : `&end=${dateFormater(data.dateTo)}`;
      const searchStatus = changeStatus === "" ? "" : `&status=${changeStatus}`;
      const searchMobile = phoneNum === "" ? "" : `&mobile=${phoneNum}`;

    //   if (fromDate !== "" && toDate === "") {
    //     swal({
    //       text: "Please select to date",
    //       icon: "error",
    //       timer: 2000,
    //       buttons: false,
    //     });
    //     return; // Return early if reason is not selected when status is 'reject'
    //   }
    //   if (fromDate === "" && toDate !== "") {
    //     swal({
    //       text: "Please select from date",
    //       icon: "error",
    //       timer: 2000,
    //       buttons: false,
    //     });
    //     return; // Return early if reason is not selected when status is 'reject'
    //   }
    //   if (
    //     fromDate !== "" &&
    //     toDate !== "" &&
    //     new Date(fromDate) > new Date(toDate)
    //   ) {
    //     swal({
    //       text: "From date should be less than to date",
    //       icon: "error",
    //       timer: 2000,
    //       buttons: false,
    //     });
    //     return; // Return early if "from" date is greater than or equal to "to" date
    //   }
      setLoading(true);
      axios
        .get(
          Constants.baseUrl +
            `reports/raiseTicket/list?sortBy=createdDateTime&sortAsc=false${searchStatus}${searchFromDate}${searchToDate}${searchMobile}&limit=${limit}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results);
              setTotalPages(res.data.output.totalPages);
              setSearchRecord();
              setLoading(false);
            } else if (res.data.code === 12002) {
              setSearchRecord(res.data.output.results);
              setTotalPages(res.data.output.totalPages);
              setLoading(false);
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const dateFormater = (dateVal) => {
    const dateObject = new Date(dateVal);

    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);
    const hours = ("0" + dateObject.getHours()).slice(-2);
    const minutes = ("0" + dateObject.getMinutes()).slice(-2);
    const seconds = ("0" + dateObject.getSeconds()).slice(-2);

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate
  };

  const download = () => {
    let token = localStorage.getItem("token");
    try {
      const searchFromDate = fromDate === "" ? "" : `&start=${start}`;
      const searchToDate = toDate === "" ? "" : `&end=${end}`;
      const searchStatus = changeStatus === "" ? "" : `&status=${changeStatus}`;
      setLoading(true);
      axios
        .get(
          Constants.baseUrl +
            `reports/download/raiseTicket?status=${searchStatus}&mobile=${phoneNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "ticketReport.csv");
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearchQuery = (event) => {
    setSelectStatus(event.target.value);
    // if (event.target.value === '') {
    //     customerCareReport('')
    //     setSearchRecord()
    // }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <HeaderSales />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="#Section1" role="tab" data-toggle="tab">
                    {" "}
                    Ticket Report
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <form onSubmit={searchFormik.handleSubmit}>
                  <div className="row clearfix mb-15">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>
                          From Date{" "}
                        </label>
                        <DatePicker
                          id="dateFrom"
                          selected={
                            searchFormik.values.dateFrom
                              ? new Date(searchFormik.values.dateFrom)
                              : null
                          }
                          onChange={(date) =>
                            searchFormik.setFieldValue("dateFrom", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>
                          To Date{" "}
                        </label>
                        <DatePicker
                          id="dateTo"
                          selected={
                            searchFormik.values.dateTo
                              ? new Date(searchFormik.values.dateTo)
                              : null
                          }
                          onChange={(date) =>
                            searchFormik.setFieldValue("dateTo", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>
                          Mobile No.{" "}
                        </label>
                        <input
                          id="mobile"
                          className="form-control"
                          type="text"
                          maxLength="10"
                          value={phoneNum}
                          onChange={(e)=> setPhoneNum(e.target.value)}
                          pattern="[0-9]*"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Status</label>
                        <select
                          id="status"
                          className="form-control2"
                          value={changeStatus}
                          onChange={(e) => setChangeStatus(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="OPEN">Open</option>
                          <option value="CLOSED">Close</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label className="invisible" style={{ width: "100%" }}>
                          hidden
                        </label>
                        <button type="submit" className="btn login_btn">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title">
                          Ticket Report
                          {searchRecord === null ? (
                            ""
                          ) : (
                            <Link
                              to="#"
                              className="pull-right"
                              onClick={download}
                            >
                              <i className="fa fa-file-excel-o"></i> Export
                            </Link>
                          )}
                        </h4>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          className="table table-striped mb_01"
                          id="table-to-xls"
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>RO Name</th>
                              <th>SH Name</th>
                              <th>Sales Name</th>
                              <th>Name</th>
                              <th>Mobile Number</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Concern ID</th>
                              <th>Date Of Concern</th>
                              <th>Concern Type</th>
                              <th>Description</th>
                              <th>Concern Status</th>
                            </tr>
                          </thead>
                          {searchRecord === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="13"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {results.map((item, index) => {
                                const serialNumber =
                                  (currentPage - 1) * limit + index + 1;
                                const inputDate = item.createdDateTime;
                                const formattedDate = moment(
                                  inputDate,
                                  "DD-MM-YYYY HH:mm"
                                ).format("DD-MM-YYYY");

                                return (
                                  <>
                                    <tr>
                                      <td>{serialNumber}</td>
                                      <td>{item.user.roName}</td>
                                      <td>{item.user.shName}</td>
                                      <td>{item.user.parentName}</td>
                                      <td>{item.user.name}</td>
                                      <td>{item.user.mobile}</td>
                                      <td>{item.user.addressWork.workCity}</td>
                                      <td>{item.user.addressWork.workState}</td>
                                      <td>{item.id}</td>
                                      <td>{formattedDate}</td>
                                      <td>{item.type}</td>
                                      <td>{item.description}</td>
                                      <td>{item.status}</td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
