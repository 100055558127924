import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../Constants/index";
import { useFormik } from "formik";
import HeaderSales from "../common/HeaderSales";
import Footer from "../common/Footer";

const NewSalesQrTransactionReport = () => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [currentPage, setCurrentPage] = useState(1); // Current active page

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      qrCode: "",
    },
    onSubmit: (values) => {
      setSearchUser(values); // Update searchUser state with form values
      fetchApi(values, 1); // Fetch data with new filters
    },
  });

  const [searchUser, setSearchUser] = useState(searchFormik.values);

  useEffect(() => {
    fetchApi(searchUser, currentPage);
  }, [currentPage]);

  const fetchApi = async (filter, pageNum = 1) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${Constants.baseUrl}reports/qrTrans/list?userType=MEMBER&mobile=${filter.mobile}&code=${filter.qrCode}&limit=${limit}&page=${pageNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        }
      );
      setData(response?.data?.output?.results); // Set fetched data
      setTotalPages(response?.data?.output?.totalPages || 1); // Set total number of pages
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  const downloadData = async () => {
    const token = localStorage.getItem("token");
    const searchUserN = `mobile=${searchUser.mobile}`;
    const searchByQr = `code=${searchUser.qrCode}`;

    try {
      const response = await axios.get(
        `${Constants.baseUrl}reports/download/qrTransreport?${searchUserN}&${searchByQr}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
          responseType: "blob", // Set the responseType to 'blob'
        }
      );

      // Create a link element to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "qrTransreport.csv"); // Set the file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      link.remove(); // Clean up the link element
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };


  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchApi(searchUser, newPage); // Fetch data for the new page
  };

  return (
    <>
      <HeaderSales />
      <div className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <p style={{ fontSize: "30px" }} role="tab" data-toggle="tab">
                    QR Transaction Report
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <form
              onSubmit={searchFormik.handleSubmit}
              className="d-flex align-items-center"
            >
              <input
                type="text"
                name="mobile"
                placeholder="Mobile Number"
                className="form-control"
                style={{ width: "30%", marginRight: "10px" }}
                onChange={searchFormik.handleChange}
                value={searchFormik.values.mobile}
              />
              <input
                type="text"
                name="qrCode"
                placeholder="QR Code"
                className="form-control"
                style={{ width: "30%", marginRight: "10px" }}
                onChange={searchFormik.handleChange}
                value={searchFormik.values.qrCode}
              />
              <button type="submit" className="btn bg-info">
                Search
              </button>

              <button
                onClick={downloadData}
                className="btn bg-success text-white"
                style={{ marginLeft: "10px" }}
              >
                Download
              </button>
            </form>
          </div>

          {/* Table and Pagination */}
          <div className="row mt-4">
            <div className="table-responsive">
              <table className="table table-striped mb_01">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Member Name</th>
                    <th>Mobile Number</th>
                    <th>DOJ</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Address Registered By Sales/Marketing Name</th>
                    <th>Approved by Sales/Marketing Name</th>
                    <th>Date of Scan</th>
                    <th>Scan ID</th>
                    <th>Product Category</th>
                    <th>Product SubCategory</th>
                    <th>SAP Code</th>
                    <th>QR Details</th>
                    <th>Transaction Time</th>
                    <th>Base Stars</th>
                    <th>Stars</th>
                    <th>Total Stars</th>
                    <th>Status</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1 + (currentPage - 1) * limit}</td>
                        <td>{item.user.name}</td>
                        <td>{item.user.mobile}</td>
                        <td>{item.doj}</td>
                        <td>{item.user.addressWork?.workState}</td>
                        <td>{item.user.addressWork?.workCity}</td>
                        <td>{item.user.addressWork?.workAddress1}</td>
                        <td>{item.user?.parentName}</td>
                        <td>{item.scanDate}</td>
                        <td>{item.id}</td>
                        <td>{item.programProduct?.category}</td>
                        <td>{item.programProduct?.subCategory}</td>
                        <td>{item.programProduct?.sapCode}</td>
                        <td>{item.code}</td>
                        <td>{item.createDateTime}</td>
                        <td>{item.pointsEarned}</td>
                        <td>{item.user?.points}</td>
                        <td>{item.user?.totalPoints}</td>
                        <td>{item.status}</td>
                        <td>{item.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="20" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pagination */}
          <div className="row">
            <div className="col-md-12 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i + 1}
                      className={`page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default NewSalesQrTransactionReport;
