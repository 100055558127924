import React, { useState } from 'react'
import logo_2 from '../assets/img/logo/logo2.png'
import about_cera from '../assets/img/aboutcera.png'
import home from '../assets/img/home.png'
import cera_icon from '../assets/img/ceraicon.png'
import about from '../assets/img/about.png'
import cera_look_book from '../assets/img/cera_look_book.pdf'
import lookbook from '../assets/img/lookbook.png'
import contact from '../assets/img/contact.png'
import bgvdo from '../assets/img/bgvdo.mp4'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "./Constants/index"
// import { event } from 'jquery'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
export default function ForgotPassword() {
    const [year] = useState(new Date().getFullYear())
    const [mobile, setMobile] = useState('')
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState('1a');
    const navigate = useNavigate()

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } else if (!/^\d+$/.test(mobile)) {
            errors.mobile = 'User ID should be numeric';
        } else if (mobile.length !== 10) {
            errors.mobile = 'User ID should be 10 digits';
        }


        return errors;
    };

    const handleSubmit = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }

        try {
            await axios.post(Constants.baseUrl + 'auth/forget-password',
                {
                    mobile: mobile,
                    password: ''
                },
                {
                    headers: {
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            })
                            setTimeout(() => {
                                // window.location = '/'
                                navigate('/')
                            }, 2000);
                        } else if (res.data.code === 12002) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });

                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleClick = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <>
            <video autoPlay muted loop className="bg_video vdofix">
                <source src={bgvdo} type="video/mp4" />
                <source src={bgvdo} type="video/webm" />
                your browser is not supported
            </video>
            <section className="loginsec">
                <div className="container">
                    <div className="loginwindow">
                        <div className="row mr0">
                            <div className="col-md-4 loginside">
                                <div className="formlogin forgot">
                                    <figure className="logomob">
                                        <img src={logo_2} alt='' />
                                    </figure>
                                    <h2>Forgot Password</h2>
                                    <div className="form-group">
                                        <label for="user id">User ID</label>
                                        <input type="text" placeholder="User ID" className="form-control frm" maxLength='10' value={mobile} onChange={(event) => {
                                            const input = event.target.value;
                                            if (/^\d*$/.test(input)) {
                                                setMobile(input);
                                            }
                                        }} onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.mobile && (
                                        <div className="error-message">{errors.mobile}</div>
                                    )}
                                    <div className="form-group mtop20">
                                        <input type="submit" value="Submit" className="btn loginbuttn" onClick={handleSubmit} />
                                    </div>
                                    {/* <!-- <div className="loggedin">
                                                    <label><input type="checkbox" /> Keep me logged in</label>
                                                </div> --> */}

                                    <div className="forgtPassword">
                                        <Link to="/">Login Here?</Link>
                                    </div>
                                    <div className="copyrighttext text-center hidden-xs">
                                        <p>Copyright © {year}
                                            <Link to="http://evolvebrands.com/" target="_blank">Evolve Brands Pvt Ltd</Link>,
                                            <br /> All Rights Reserved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 logoside">
                                <figure className="abtright hidden-xs">
                                    <img src={about_cera} alt="" />
                                    {/* <!-- <p>
                                                        CERA StarPlumber is a loyalty program that brings reward benefits to the associated partners (Plumbers) on every purchase made from CERA authorized dealers and retailers. The purpose of this program is to strengthen the relationship with the Plumbers and provide transparent communication
                                                    </p> --> */}
                                </figure>
                                {/* 
                                            <!-- <div className="bottom-nav">
                                                <ul className="bottom-menu">
                                                    <li><Link to="index.html"> <img src="img/home.png"> Home</Link></li>
                                                    <li><Link to="about_cera.html"> <img src="img/ceraicon.png">  About Cera</Link></li>
                                                    <li><Link to="about_cera_star_plumber.html"><img src="img/about.png"> About Cera StarPlumber</Link></li>
                                                    <li><Link to="img/cera_look_book.pdf" target="_blank"><img src="img/lookbook.png"> Cera Look Book</Link></li>
                                                    <li><Link to="contact.html"> <img src="img/contact.png"> Contact Us</Link></li>
                                                </ul>
                                            </div> --> */}
                                <div id="tabbing">
                                    <div className="tab-content clearfix">
                                        <div className={`tab-pane ${activeTab === '1a' ? 'active' : ''}`} id="1a">
                                            <p>
                                                CERA StarPlumber is a loyalty program that brings reward benefits to the associated  Plumbers on every purchase made from CERA authorized dealers and retailers. <br /> The purpose of this program is to strengthen the relationship with the Plumbers and provide transparent communication.
                                            </p>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '2a' ? 'active' : ''}`} id="2a">
                                            <div className="aboutceraindia" id="scrollabt">
                                                <p className="innovate">Style. Innovation. Leadership.</p>
                                                <p>
                                                    These three words capture the essence of CERA Sanitaryware Limited, One of India's fastest-growing company in the segment. Keeping the needs of the modern customer in mind,we have opened the doorway to the world of style.
                                                </p>
                                                <p>
                                                    Our constant innovations have provided several path- breaking contributions to the industry-like water- saving twin-flush coupled WCs, 4-litre flush WCs, one- piece  WCs, etc.
                                                </p>
                                                <p>
                                                    Advanced technology has been the forte of CERA. Its state-of-the-art manufacturing plant has been following the highest standards of quality with an emphasis on sustainability since its inception in 1980. The production capacity of sanitaryware has been elevated from 2.7 million pieces to 3 million pieces per annum. We always plan to maintain our leadership status in the industry while catering to increased demands and effortless supply across the nation.
                                                </p>
                                                <p>
                                                    With CERA, style blends with innovation to reveal a new dimension of modernity.

                                                </p>

                                            </div>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '3a' ? 'active' : ''}`} id="3a">
                                            <p>
                                                CERA StarPlumber is a loyalty program that brings reward benefits to the associated  Plumbers on every purchase made from CERA authorized dealers and retailers. <br /> The purpose of this program is to strengthen the relationship with the Plumbers and provide transparent communication.
                                            </p>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '4a' ? 'active' : ''}`} id="4a">
                                            <div className="row">
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">Address</h4>
                                                    <address>
                                                        C/o Evolve Brands Pvt Ltd
                                                        <br />
                                                        249 G, AIHP Tower,

                                                        Level 2,<br /> Wing A,
                                                        Udyog Vihar Phase 4,
                                                        Gurugram,122015


                                                    </address>
                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">May I help you</h4>
                                                    {/* <!--<i className="fa fa-phone" aria-hidden="true"></i> : 011-39588398--> */}
                                                    <i className="fa fa-phone" aria-hidden="true"></i> : 18002026182
                                                    <br />
                                                    <i className="fa fa-envelope" aria-hidden="true"></i> : info@cerastarplumber.com

                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">Our Timings</h4>
                                                    (Monday to Friday)<br />(09:30am to 5:30pm)
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="bottom-menu">
                                        <li className={activeTab === '1a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('1a')} data-toggle="tab" aria-expanded={activeTab === '1a'}>
                                                <img src={home} alt="" /> Home
                                            </Link>
                                        </li>
                                        <li className={activeTab === '2a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('2a')} data-toggle="tab" aria-expanded={activeTab === '2a'}>
                                                <img src={cera_icon} alt="" /> About Cera
                                            </Link>
                                        </li>
                                        <li className={activeTab === '3a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('3a')} data-toggle="tab" aria-expanded={activeTab === '3a'}>
                                                <img src={about} alt="" /> About Cera StarPlumber
                                            </Link>
                                        </li>
                                        <li>
                                            <a href={'https://cera-loyalty.s3.ap-south-1.amazonaws.com/CERA+-Product+Catalogue.pdf'} target="_blank">
                                                <img src={lookbook} alt="" /> Cera Look Book
                                            </a>
                                        </li>
                                        <li className={activeTab === '4a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('4a')} data-toggle="tab" aria-expanded={activeTab === '4a'}>
                                                <img src={contact} alt="" /> Contact Us
                                            </Link>
                                        </li>
                                    </ul>

                                    {/* <div className="bottom-nav">
                                        <ul className="bottom-menu">
                                            <li className="active"><Link to="#1a" data-toggle="tab" aria-expanded="true"> <img src={home} alt="" /> Home</Link></li>
                                            <li><Link to="#2a" data-toggle="tab" aria-expanded="false"> <img src={cera_icon} alt="" />  About Cera</Link></li>
                                            <li><Link to="#3a" data-toggle="tab" aria-expanded="false"><img src={about} alt="" /> About Cera StarPlumber</Link></li>
                                            <li><Link to={cera_look_book} target="_blank"><img src={lookbook} alt="" /> Cera Look Book</Link></li>
                                            <li><Link to="#4a" data-toggle="tab" aria-expanded="false"> <img src={contact} alt="" /> Contact Us</Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
