import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import * as Constants from './Constants/index';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart() {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Quantity',
                data: [],
                backgroundColor: '#90c4e2',
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            let token = localStorage.getItem('token');
            try {
                const response = await axios.post(Constants.baseUrl + `capture/qr/product_report_trend`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Accept-Language": localStorage.getItem("language"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                        company: Constants.company,
                    },
                });
    
                if (response.status === 201 && response.data.code === 10001) {
                    const apiData = response.data.output;
                    const labels = apiData.map(item => item.category);
                    const data = apiData.map(item => item.total_scan);
                    
                    // Modify labels to only include the first word
                    let formattedNames = labels.map(name => {
                        const parts = name.split(' ');
                        // Take the first and second parts and join with a line break
                        return `${parts[0]}\n${parts[1] || ''}`;  // Safely access the second part if available
                    });
    
                    setChartData({
                        labels: formattedNames,
                        datasets: [
                            {
                                label: 'Quantity',
                                data: data,
                                backgroundColor: '#90c4e2',
                            },
                        ],
                    });
                    console.log("labels...", formattedNames);
                }
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Product Category Performance',
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: function(value, index, values) {
                        // Use this.getLabelForValue to get the label string and split it on line breaks
                        return this.getLabelForValue(value).split('\n');
                    },
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
        },
    };

    return (
        <div className="col-md-5 col-sm-12 col-xs-12 col40" style={{ width: "100%", marginTop: "20px" }}>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title text-center">
                        Product Category Performance
                    </h3>
                </div>
                <div className="card-body">
                    <Bar data={chartData} options={options} />
                </div>
            </div>
        </div>
    );
}
