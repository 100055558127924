import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './Login'
import LoginWithOtp from './LoginWithOtp'
import ForgotPassword from './ForgotPassword'
import Terms_Conditions from './Terms_Conditions'
import SalesDashboard from './SalesDashboard'
import AddNewPlumber from './AddNewPlumber'
import PendingForApproval from './PendingForApproval'
import ApprovedPlumber from './ApprovedPlumber'
import RejectedPlumber from './RejectedPlumber'
import SalesEnrollmentReport from './Sales_Reports/SalesEnrollmentReport'
import EnrollkmentReport from './Sales_Reports/EnrollkmentReport'
// import SalesLoginReport from './Sales_Reports/SalesLoginReport'
import SalesQrTransactionReport from './Sales_Reports/SalesQrTransactionReport'
import SalesErrorQrTransactionReport from './Sales_Reports/SalesErrorQrTransactionReport'
import SalesTopPerformanceReport from './Sales_Reports/SalesTopPerformanceReport'
import SalesRedemptionReport from './Sales_Reports/SalesRedemptionReport'
// import SalesInActiveReport from './Sales_Reports/SalesInActiveReport'
import SalesTicketReport from './Sales_Reports/SalesTicketReport'
// import SalesProductWiseReport from './Sales_Reports/SalesProductWiseReport'
import Protected from './Protected'
import Page_404 from './Page_404'
import Privacy_Ploicy from './Privacy_Ploicy'
import NewSalesQrTransactionReport from './Sales_Reports/NewSalesQrTransactionReport'
// import ChangePassword from './ChangePassword'
// import PendingForAdmin from './PendingForAdmin'
export default function App() {

  localStorage.setItem('language', 'en')
  // const userType = localStorage.getItem('user_type')

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login_with_otp" element={<LoginWithOtp />}></Route>
          <Route path="/forgot_password" element={<ForgotPassword />}></Route>
          <Route path="/app/tnc" element={<Terms_Conditions />}></Route>
          <Route
            path="/sales_dashboard"
            element={
              <Protected
                Cmp={SalesDashboard}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/add_new_plumber"
            element={
              <Protected Cmp={AddNewPlumber} requiredRoles={["SALES", "MK"]} />
            }
          ></Route>
          <Route
            path="/pending_for_approval"
            element={
              <Protected
                Cmp={PendingForApproval}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/approved_plumber"
            element={
              <Protected
                Cmp={ApprovedPlumber}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/rejected_plumber"
            element={
              <Protected
                Cmp={RejectedPlumber}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          {/* <Route path='/sales_enrollment_report' element={<Protected Cmp={SalesEnrollmentReport} requiredRoles={['SALES', 'SH', 'RO', 'HO', 'MK']} />}></Route> */}
          <Route
            path="/sales_enrollment_report"
            element={
              <Protected
                Cmp={EnrollkmentReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          {/* <Route path='/sales_login_report' element={<Protected Cmp={SalesLoginReport} />}></Route> */}
          <Route
            path="/sales_qr_transaction_report"
            element={
              <Protected
                Cmp={SalesQrTransactionReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/sales_error_qr_transaction_report"
            element={
              <Protected
                Cmp={SalesErrorQrTransactionReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/sales_top_performance_report"
            element={
              <Protected
                Cmp={NewSalesQrTransactionReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          <Route
            path="/sales_redemption_report"
            element={
              <Protected
                Cmp={SalesRedemptionReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          {/* <Route path='/sales_inactive_report' element={<Protected Cmp={SalesInActiveReport} />}></Route> */}
          <Route
            path="/sales_ticket_report"
            element={
              <Protected
                Cmp={SalesTicketReport}
                requiredRoles={["SALES", "SH", "RO", "HO", "MK"]}
              />
            }
          ></Route>
          {/* <Route path='/sales_product_wise_report' element={<Protected Cmp={SalesProductWiseReport} />}></Route> */}
          <Route path="/*" element={<Page_404 />}></Route>
          <Route path="/privacy-policy" element={<Privacy_Ploicy />}></Route>
          <Route
            path="new_sales_qr_transaction_report"
            element={<NewSalesQrTransactionReport />}
          ></Route>
          {/* <Route path='/pending_for_admin' element={<Protected Cmp={PendingForAdmin} />}></Route> */}
          {/* <Route path='/changePassword' element={<Protected Cmp={ChangePassword} />}></Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}
