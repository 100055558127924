import React from 'react'

export default function Privacy_Ploicy() {
    return (
        <div className="container terms">

            <h3>Privacy Policy:</h3>
            <ul>
                <li>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.</li>
                <li>This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                <li>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of this mobile application ('App').</li>
                <li>The App is owned by Evolve Brands Private Limited(EBPL), a company incorporated under the Companies Act, 1956 with its registered office at 186, Munirka Vihar, New Delhi – 110067 (hereinafter referred to as 'EBPL').</li>
                <li>Your usage of this App is under the guidelines of the contract set between EBPL and CERA Sanitaryware Ltd. exclusively for managing the “CERA StarPlumber” Reward Program.</li>
                <li>The usage of this app is strictly goverened by the rules of the “CERA StarPlumber” Reward Program and is available only on invitation by CERA Sanitaryware Ltd.</li>
                <li>By merely using the App, you shall be deemed to have contracted with EBPL and have agreed to be bound by the terms and conditions herein contained, including the various policies incorporated by reference in this Agreement</li>
                <li>For the purpose of these Terms of Use, wherever the context so requires 'You' or 'User' shall mean any natural or legal person who has agreed to become a member by using the App by providing Registration Data while registering on the App as Registered User. The term 'We', 'Us', 'Our' shall mean EBPL.</li>
                <li>We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the App following the posting of changes will mean that you accept and agree to the revisions. As long as you comply with these Terms of Use, We grant you a personal, non-exclusive, non-transferable, limited privilege to enter and use the App.</li>
                <li>ACCESSING OR OTHERWISE USING THE APP INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by EBPL Policies (including but not limited to Privacy Policy) as amended from time to time.</li>
            </ul>

            <p><b>Membership Eligibility:</b></p>
            <p>
                Only persons who can form legally binding contracts under Indian Contract Act, 1872 are permitted to use the App. Any person who is 'incompetent to contract' within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the App. If you are a minor i.e. under the age of 18 years, and you wish to use or transact on the App, you must only transact using the App registered under the name of a legal guardian or parents. EBPL reserves the right to terminate your membership and / or refuse to provide you with access to the App if it is brought to EBPL notice or if it is discovered that you are ineligible to use the App.</p>
            <p><b>Your Account and Registration Obligations:</b></p>
            <p>You are solely and entirely responsible for maintaining the confidentiality and security of your Account, Display Name and password and for restricting access to your mobile device. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, we shall have the right to indefinitely suspend or terminate or block your access to the App. You agree to accept responsibility for all activities that occur under your account or password including any content stored, posted or otherwise transmitted through your account even when certain content is posted by others who have access to your account with or without your consent/authorization. We will not be responsible for any changes to the content in your account, including without limitation, product listings or prices, that are attributable to use of your account by any third party, with or without your consent and you hereby expressly agree not to hold us liable in such a case .We shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account and the loss or damage arising therefrom.</p>
            <p>If you know or suspect that someone unauthorized knows your password or any other security breach, then you should notify us by contacting us immediately and in no event later than 24 hours through the address provided in the 'Contact Us' section of the website. If EBPL has reason to believe that there is likely to be a breach of security or misuse of the App, we may require you to change your password or we may suspend your account without any liability to EBPL and without any prior notice to you. Without prejudice to the above, EBPL reserves the right to recover from you any and all damages, losses or costs that are levied on or suffered by EBPL due to misuse of your account or password whether due to reasons attributable to you or not. It is very important that you log out when you have finished using the App and preferably every time you leave your mobile device unattended. If you leave yourself logged in, then someone else may use your mobile device and have access to your Account. They may read, modify, or delete content from your Account. They may send emails out from your user ID. They may even attempt to hack or damage the system from your user ID communications.</p>
            <p>When You use the App or send emails or other data, information or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive communications via electronic records from us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
            <p><b>Platform for Transaction and Communication:</b></p>
            <p>The App is a platform that users utilize to meet and interact with one another for their transactions. EBPL is not and cannot be a party to or control in any manner any transaction between the App's Users. Consequently:</p>
            <ol>
                <li>All commercial/contractual terms are offered and agreed to by and between Members and Principal alone. The commercial/contractual terms include as applicable and without limitation price, points calculation, points deduction, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. EBPL does not have any control over nor does it determine or advise or in any way involve itself in the negotiation, offering or acceptance of such commercial/contractual terms between the Members and Manufacturing companies.</li>
                <li>EBPL does not make any representation or Warranty as to specifics (such as quality, value, salability, etc) of the products or services proposed to be redeemed on the App. EBPL accept no liability for any errors or omissions, whether on behalf of itself or third parties.</li>
                <li>EBPL is not responsible for any non-performance or breach of any contract entered into between Members and Principal. EBPL cannot and does not guarantee that the concerned members and/or Product manufacturing companies will perform any transaction concluded on the App. EBPL shall not and is not required to mediate or resolve any dispute or disagreement between members and Product manufacturing companies</li>
                <li>EBPL does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that you choose to deal with on the App and use your best judgment in that behalf.</li>
                <li>At no time shall EBPL hold any right, title or interest over the products nor shall EBPL have any obligations or liabilities in respect of such contract entered into between members and Product manufacturing companies. EBPL is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</li>
                <li>The App is only an application that provides access to the technology platform managed by EBPL that can be utilized by Users to report transactional data, earn reward points and redeem those reward points. EBPL is only providing a platform for capturing information and communication.</li>
                <li>You shall independently agree upon the manner and terms and conditions of delivery, payment, insurance etc. with the Product manufacturing companies (s)</li>
                <li>You release and indemnify EBPL and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the App and specifically waive any claims that you may have in this behalf under any applicable law. Notwithstanding its reasonable efforts in that behalf, EBPL cannot take responsibility or control the information provided by other Users which is made available on the App. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the App.</li>
            </ol>
            <p><b>Disclaimer:</b> In the event the points of any product(s) listed on the App is, due to some technical issue, typographical error or mistake published by a Seller, and incorrectly reflected, EBPL may cancel such your order. In any such event EBPL shall not be liable for and you hereby release and indemnify EBPL and/or any of its officers and representatives from any cost, damage, liability or other consequence of such cancellation of the order.</p>
            <p><b>Please note that there could be risks in dealing with underage persons or people acting under false pretence.</b></p>
            <p><b>Charges</b></p>
            <p>Use of the App is free for users. EBPL does not charge any fee for using the App and redeeming using the App from users but the expense of running and maintaining this app is borne by the EBPL and the principal company whose members can use this FREE. EBPL reserves the right to change its Fee Policy from time to time. In particular, EBPL may at its sole discretion introduce new services and modify some or all of the existing services offered through the App. In such an event, EBPL reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be intimated to users of the App and such changes shall automatically become effective immediately thereafter. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws including those in India for making payments to EBPL</p>
            <p><b>Use of the App</b></p>
            <p>You agree, undertake and confirm that your use of App shall be strictly governed by the following binding principles:</p>
            <ol>
            <li>
            You shall not host, display, upload, modify, publish, transmit, update or share any information which: .
            </li>
            </ol>
            <ol>
            <dd>Belongs to another person and to which you do not have any right to;</dd>
            </ol>
            <ol>
            <dd>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasion of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to 'indecent representation of women' within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</dd>
            </ol>

            <ol>
            <dd>is misleading in any way;</dd>
            </ol>
            <ol>
            <dd>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</dd>
            </ol>

            <ol>
            <dd>harasses or advocates harassment of another person;</dd>
            </ol>

            <ol>
            <dd>involves the transmission of 'junk mail', 'chain letters', or unsolicited mass mailing or 'spamming';</dd>
            </ol>

            <ol>
            <dd>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</dd>
            </ol>

            <ol>
            <dd>infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity]; </dd>
            </ol>

            <ol>
            <dd>promotes an illegal or unauthorized copy of another person's copyrighted work (see 'Copyright complaint' below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;s </dd>
            </ol>

            <ol>
            <dd>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</dd>
            </ol>

            
            <ol>
            <dd>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</dd>
            </ol>

            <ol>
            <dd>contains video, photographs, or images of another person (with a minor or an adult).</dd>
            </ol>

            <ol>
            <dd>(n) tries to gain unauthorized access or exceeds the scope of authorized access to the App or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the App or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</dd>
            </ol>

            <ol>
            <dd>engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of 'virtual' products related to the App. Throughout this Terms of Use, EBPL 's prior written consent means a communication coming from EBPL 's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</dd>
            </ol>

            <ol>
            <dd>solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</dd>
            </ol>

            <ol>
            <dd>interferes with another USER's use and enjoyment of the App or any other individual's User and enjoyment of similar services;</dd>
            </ol>

            <ol>
            <dd>refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the App or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use</dd>
            </ol>

            <ol>
            <dd>harm minors in any way;</dd>
            </ol>

            <ol>
            <dd>infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</dd>
            </ol>

            <ol>
            <dd>violates any law for the time being in force;</dd>
            </ol>

            <ol>
            <dd>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</dd>
            </ol>

            
            <ol>
            <dd>impersonate another person;</dd>
            </ol>

            <ol>
            <dd>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</dd>
            </ol>

            <ol>
            <dd>threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</dd>
            </ol>

            <ol>
            <dd>shall be false, inaccurate or misleading;</dd>
            </ol>

            <ol>
            <dd>shall directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</dd>
            </ol>

            <ol>
            <dd>shall create liability for Us or cause Us to lose (in whole or in part) the </dd>
            </ol>
            <br></br>
  <p>services of Our internet service provider ('ISPs') or other suppliers</p>
  <ol>
    <li className='d-none-hidden' style={{	visibility: 'hidden'}}></li>
    <li>You shall not use any program, algorithm or methodology, or any similar or equivalent manual process, to copy or, in any way reproduce or circumvent the navigational structure or presentation of the Content on the App,, to obtain or attempt to obtain any data or information about the App or the Content through any means other than that which has been purposely made available through the App. We reserve our right to bar any such activity.</li>
    <li>You shall not attempt to gain unauthorized access to any portion or feature of the App, or any other systems or networks connected to the App, by hacking, password 'mining' or any other illegitimate means.</li>

    <li>You shall not probe, scan or test the vulnerability of the App or any network connected to the App nor breach the security or authentication measures on the App or any network connected to the App. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to App, or any other customer, including any account on the App not owned by You, to its source, or exploit the App or any service or information made available or offered by or through the App, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the App.</li>

    <li>You shall not probe, scan or test the vulnerability of the App or any network connected to the App nor breach the security or authentication measures on the App or any network connected to the App. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to App, or any other customer, including any account on the App not owned by You, to its source, or exploit the App or any service or information made available or offered by or through the App, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the App.</li>

    <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the terms EBPL or RAPr or otherwise engage in any conduct or action that might tarnish the image or reputation of EBPL or otherwise tarnish or dilute any EBPL’s trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the App or EBPL’s systems or networks, or any systems or networks connected to EBPL.</li>
    <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the App or any transaction being conducted on the App, or with any other person's use of the App.</li>

    <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the App or any service offered on or through the App. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity or access the App through another user's account, unless authorized to do so</li>

    <li>You may not use the App or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of EBPL and / or others.</li>

    <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations (including, but not limited to Sales Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty, Local Levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>

    <li>Solely to enable us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with the Terms of Use and Privacy Policy applicable to use of the App.</li>

    <li>11.	From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or over emphasize the attributes of such products or services so as to mislead other users in any manner.</li>

    <li>You shall not engage in advertising to, or solicitation of, other users of the App to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the App or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the App. It shall be a violation of these Terms of Use to use any information obtained from the App in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, we reserve the right to restrict the messages or emails which a user may send to other Users in any 24-hour period to a number which we deem appropriate in our sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the App) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>

    <li>We reserve the right, but have no obligation, to monitor the materials posted on the App. EBPL shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, you remain solely responsible for the content of the materials you post on the App and in your private messages. Please be advised that such Content posted does not necessarily reflect EBPL views. In no event shall EBPL assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the App. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information.</li>


    <li>It is possible those other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the App and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the App, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the App You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the App. Please carefully select the type of information that you publicly disclose or share with others on the App.</li>

    <li>EBPL shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your owner through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>

    <li>16.	Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the App, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the App</li>


    </ol>

    <p><b>Contents Posted on the App</b></p>

    <ul>
        <li>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork (collectively, 'Content'), is a third party user generated content and EBPL has no control over such third party user generated content as EBPL is merely an intermediary for the purposes of this Terms of Use, except as expressly provided in the Terms of Use, no part of the App and no content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including 'mirroring') to any other computer, server, App, website or other medium for publication or distribution or for any commercial enterprise, without EBPL 's express prior written consent</li>

        <li>You may use information on the products and services purposely made available on the App for downloading, if any, provided that you (1) do not remove any proprietary notice language in all copies of such documents, (2) use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) do not make any additional representations or warranties relating to such documents.</li>

        <li> You shall be responsible for any notes, messages, emails, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the App (collectively, 'Content'). Such Content will become our property and you grant Us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include the Content You provide. You agree that any Content You post may be used by us, consistent with Our Privacy Policy and Rules of Conduct on the App as mentioned herein, and you are not entitled to any payment or other compensation for such use.</li>



        
    </ul>


    <p><b>Privacy</b></p>
    <ul><li>We view protection of your privacy as a very important principle. We understand clearly that You and Your Personal Information is one of our most important assets. We store and process Your Information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under. If you object to Your Information being transferred or used in accordance with our Privacy Policy, available on the App, please do not use App.</li>

    <li>We and our affiliates will share / sell / transfer / license / covey some or all of your personal information with another business entity should we (or our assets) plan to merge with or are acquired by that business entity, or re-organization, amalgamation, restructuring of business or for any other reason whatsoever. Should such a transaction or situation occur, the other business entity or the new combined entity will be required to follow the privacy policy with respect to your personal information. Once you provide your information to us, you provide such information to us and our affiliate and we and our affiliate may use such information to provide you various services with respect to your transaction whether such transactions are conducted on the App or with third party merchant's application or website, as the case may be. We may share personal information with our other corporate entities and affiliates. These entities/affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may disclose personal information to third parties for commercial purposes. This disclosure may be required for us to, including but not limited to, provide you access to our Services, to comply with our legal obligations, to enforce the terms of use, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We may disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent. We may also disclose some personal information to third parties, on a commercial fee arrangement with us for data analytics, market research and related purposes and you hereby convey no-objection to the disclosure of such personal information by us.</li>

    <li>Further, the App might contain links to third party websites, services, and advertisements for third parties (collectively, 'Third Party Sites & Ads'). Such Third Party Sites & Ads are not under the control of EBPL and EBPL is not responsible for any Third Party Sites & Ads. EBPL provides these Third Party Sites & Ads only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites & Ads. EBPL shall also not be responsible for the accuracy of information provided in the Third Party Sites & Ads and the quality of product sold by them.</li>

    <li>You use all Third Party Sites & Ads at your own risk. When you link to a Third Party Site & Ad, the applicable third party's terms and policies apply, including the third party's privacy policy. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Sites & Ads.</li>

    <li>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</li>

    
    </ul>


    <p><b>Disclaimer of Warranties and Liability</b></p>

    <p>This App, all the materials and products (including but not limited to software) and services, included on or otherwise made available to you through the App are provided on 'as is' and 'as available' basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, EBPL does not warrant that:</p>

    <ol>
    <dd>This App will be constantly available, or available at all; or</dd>
    </ol>

    
    <ol>
    <dd>The information on this App is complete, true, accurate or non-misleading. EBPL will not be liable to You in any way or in relation to the Contents of, or use of, or otherwise in connection with, the App. EBPL does not warrant that this App; information, Content, materials, product (including software) or services included on or otherwise made available to you through the App; their servers; or electronic communication sent from Us are free of viruses or other harmful components.
Nothing on App constitutes, or is meant to constitute, advice of any kind. All the Products sold on App are governed by different state laws and if Seller is unable to deliver such Products due to implications of different state laws, Seller will return or will give credit for the amount (if any) received in advance by Seller from the sale of such Product that could not be delivered to you. you will be required to enter a valid phone number while placing an order on the App. By registering your phone number with us, you consent to be contacted by us via phone calls and/or SMS notifications, in case of any order or shipment or delivery related updates. We will not use your personal information to initiate any promotional phone calls or SMS.
</dd>
    </ol>

<p><b>Compliance with Laws:</b></p>
<ul><li>Members and Product manufacturing companies along with EBPL Principal clients shall comply with all the applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made thereunder, Foreign Contribution Regulation Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable to them respectively for using Payment Facility and EBPL App.</li></ul>

<p><b>Indemnity:</b></p>
<ul><li>You shall indemnify and hold harmless EBPL , its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of your breach of this Terms of Use, privacy Policy and other Policies, or your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</li></ul>

<p><b>Applicable Law:</b></p>

<ul>
    <li>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in New Delhi.</li>

    <li>Jurisdictional Issues/Sale in India Only</li>

    <li>Unless otherwise specified, the material on the App is presented solely for the purpose of sale in India. EBPL make no representation that materials in the App are appropriate or available for use in other locations/Countries other than India. Those who choose to access the App from other locations/Countries other than India do so on their own initiative and EBPL is not responsible for supply of products/refund for the products ordered from other locations/Countries other than India, compliance with local laws, if and to the extent local laws are applicable. Trademark, Copyright and Restriction</li>

    <li>The App is controlled and operated by EBPL and products are sold by respective Sellers. All material on the App, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights.</li>

    <li>All content included on this App, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of EBPL or its Sellers, unless owned by any third party and protected by applicable copyright laws. All other such content not owned by EBPL that appear on this site are the property of their respective owners. All software used on this App is the property of EBPL and is protected by applicable copyright laws. Any unauthorized copying or reproduction of the above identified material is strictly prohibited by law.</li>

    <li>'EBPL ', the EBPL logo, RAPr logo and other marks indicated on the app are trademarks of EBPL. Other EBPL graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade dress of EBPL. EBPL’s trademarks and trade dress may not be used in connection with any product or service that is not EBPL’s, in any manner that is likely to cause confusion among users or in any manner that disparages or discredits EBPL. All other trademarks not owned by EBPL that appear on this site are the property of their respective owners.</li>

    <li>Material on App is solely for your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and you must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other website or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause.</li>


    <p><b>Limitation of Liability</b></p>

    <li>In no event shall EBPL be liable for any special, incidental, indirect or consequential damages of any kind in connection with these terms of use, even if user has been informed in advance of the possibility of such damages</li>

    <p><b>Contact Us</b></p>

    <p>Please contact us for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this App.</p>

</ul>

  
        </div>
    )
}
