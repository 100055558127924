import React, { useState, useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from 'axios';
// import swal from 'sweetalert'
import * as Constants from "./Constants/index"
export default function DonutChart() {
    const [memberActive, setMemberActive] = useState('')
    const [memberInActive, setMemberInActive] = useState('')

    useEffect(() => {

        let token = localStorage.getItem('token');

        try {
            axios.get(Constants.baseUrl + `program/dashboard/sales`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program
                }
            })
                .then((res) => {
                    if (res.data.output) {
                        setMemberActive(res.data.output.memberActive);
                        setMemberInActive(res.data.output.memberInActive);

                    } else {
                        // swal(res.data.msg);
                    }
                })

        } catch (error) {
            console.log(error)
        }

        let root = am5.Root.new("chartdiv2");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                radius: am5.percent(90),
                innerRadius: am5.percent(50),
                layout: root.horizontalLayout
            })
        );

        // Define data
        let data = [
            {
                member: "Active",
                quantity: memberActive,
            },
            {
                member: "Inactive",
                quantity: memberInActive,
            }
        ];


        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: "Series",
                valueField: "quantity",
                categoryField: "member"
            })
        );

        series.data.setAll(data);

        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        series.slices.template.set("strokeOpacity", 0);
        series.slices.template.set("fillGradient", am5.RadialGradient.new(root, {
            stops: [
                { brighten: -0.5 },
                { brighten: -0.8 },
                { brighten: -0.5 },
                { brighten: 0 },
                { brighten: -0.5 }
            ]
        }));


        let legend = chart.children.push(am5.Legend.new(root, {
            centerY: am5.percent(50),
            y: am5.percent(50),
            layout: root.verticalLayout
        }));

        legend.valueLabels.template.setAll({ textAlign: "right" });
        legend.labels.template.setAll({
            maxWidth: 140,
            width: 100,
            oversizedBehavior: "wrap"
        });

        legend.data.setAll(series.dataItems);

        series.appear(1000, 100);

        root.current = root;

        return () => {
            root.dispose();
        };
    }, [memberActive, memberInActive]);

    return (
        <>
            <div className="col-md-4 col-sm-4 col-xs-12 col37">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title text-center">Onboard Plumbers Active / Inactive</h3>
                    </div>
                    <div className="card-body">
                        <div id="chartdiv2">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
